import type { Dispatch } from 'redux'
import { sendCommandToStudio } from '../../../services/api/serverless'
import type { StateTree } from '../../interfaces'
import type { PickWinnersFailureAction } from '../interfaces/actions/PickWinnersFailureAction'
import type { PickWinnersStartAction } from '../interfaces/actions/PickWinnersStartAction'
import type { PickWinnersSuccessAction } from '../interfaces/actions/PickWinnersSuccessAction'
import type { PickWinners } from '../interfaces/PickWinners'
import type { PickWinnersResult } from '../interfaces/PickWinnersResult'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

const pickWinnersStart = (): PickWinnersStartAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.PICK_WINNERS_START }
}

const pickWinnersFailure = (): PickWinnersFailureAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.PICK_WINNERS_FAILURE }
}

const pickWinnersSuccess = (payload: PickWinnersResult): PickWinnersSuccessAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.PICK_WINNERS_SUCCESS, payload }
}

export default function pickWinners() {
	return async (dispatch: Dispatch, getState: () => StateTree): Promise<void> => {
		dispatch(pickWinnersStart())

		try {
			const {
				dashboardInputs: { prizes, seasonWinnerState },
				commands: {
					seasonTransition: { seasonTransitionStatus: seasonTransition },
					showType: { showType },
				},
			} = getState()

			const {
				currency,
				tournamentChampion: { value: tournamentPrize },
				tournamentTop10: { value: tournamentSecondaryPrize },
				beatGuestWinner: { value: liveShowPrize },
				luckyWinner: { value: randomPrize },
			} = prizes

			const pickWinnersCommand: PickWinners = {
				command: 'pickWinners',
				currency,
				tournamentPrize,
				tournamentSecondaryPrize,
				liveShowPrize,
				randomPrize,
				showType,
				seasonTransition,
				seasonWinnerUserId: seasonTransition ? seasonWinnerState.userId : '',
			}

			const result = await sendCommandToStudio<PickWinnersResult>(pickWinnersCommand)

			dispatch(pickWinnersSuccess(result))
		} catch {
			dispatch(pickWinnersFailure())
		}
	}
}
