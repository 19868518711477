import dayjs from 'dayjs'
import { nanoid } from 'nanoid'
import type { DisplayBeatGuestWSProps } from '../../redux/studioCommand/interfaces/DisplayBeatGuestWSProps'
import type { ChatMessage } from '../../redux/webSocket/interfaces/ChatMessage'
import type { GameValues } from '../../redux/webSocket/interfaces/GameValues'
import type { PickWinnersWSData } from '../../redux/webSocket/interfaces/PickWinnersWSData'
import type { StopShowWSData } from '../../redux/webSocket/interfaces/StopShowWSData'
import type { WebSocketMessageState } from '../../redux/webSocket/interfaces/WebSocketMessageState'
import type { ExecuteShow } from './interfaces/ExecuteShow'
import type { MessageEvents } from './interfaces/MessageEvents'
import type { OnMessageEventsReturn } from './interfaces/OnMessageEventsReturn'
import type { ReceivedChatMessage } from './interfaces/ReceivedChatMessage'
import type { ReceivedTopThreePlayers } from './interfaces/ReceivedTopThreePlayers'
import type { RemoveChatMessagesFromPlayerMessage } from './interfaces/RemoveChatMessagesFromPlayerMessage'

export const onMessageEvents = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	parsedMessage: Record<string, any>,
	{
		startCountdownShowAvailable,
		topThreePlayers,
		startGame,
		prepareCelebration,
		displayBeatGuest,
		pickWinners,
		resetChallenges,
		stopShow,
		executeShowStatus,
	}: WebSocketMessageState,
): OnMessageEventsReturn => {
	const event = parsedMessage.event as MessageEvents

	const newStartCountdownShowAvailable = {
		...startCountdownShowAvailable,
	}
	const newTopThreePlayers = { ...topThreePlayers }
	const newStartGame = { ...startGame }
	const newPrepareCelebration = { ...prepareCelebration }
	const newDisplayBeatGuest = { ...displayBeatGuest }
	const newPickWinners = { ...pickWinners }
	const newResetChallenges = { ...resetChallenges }
	const newStopShow = { ...stopShow }
	let executeShow = executeShowStatus
	let chatMessage = {} as ChatMessage
	let removeMessagesFromPlayerId: string | undefined

	const eventResolvers: Record<MessageEvents | 'default', () => void> = {
		channel_message: () => {
			const { userId: playFabId, content, userDisplayName } = parsedMessage as ReceivedChatMessage

			const timestamp = Date.now()
			const time = dayjs(timestamp).format('h:mm A')
			const imageId = nanoid()
			const id = `${playFabId}|${timestamp}`

			chatMessage = { id, playFabId, time, content, name: userDisplayName, imageId }
		},
		gameCountdown: () => {
			newStartCountdownShowAvailable.gameCountdown = true
		},
		startLiveShow: () => {
			newStartCountdownShowAvailable.startLiveShow = true
		},
		showTopThree: () => {
			const { players } = parsedMessage as ReceivedTopThreePlayers
			newTopThreePlayers.isAvailable = false
			newTopThreePlayers.players = players
		},
		startGame: () => {
			const values = parsedMessage as GameValues
			newStartGame.isAvailable = false
			newStartGame.values = values
		},
		displayGuestCompeteResults: () => {
			const result = parsedMessage as DisplayBeatGuestWSProps
			newDisplayBeatGuest.isAvailable = false
			newDisplayBeatGuest.result = result
		},
		winnersAnnouncement: () => {
			const { event: _event, ...props } = parsedMessage as PickWinnersWSData
			newPickWinners.isAvailable = false
			newPickWinners.data = props
		},
		stopLiveShow: () => {
			const result = parsedMessage as StopShowWSData
			newStopShow.isAvailable = false
			newStopShow.data = result
		},
		PrepareCelebrationScreen: () => {
			const { data } = parsedMessage as never
			newPrepareCelebration.isAvailable = false
			newPrepareCelebration.data = data
		},
		new24HChallenge: () => {
			console.warn('new24HChallenge', parsedMessage)
			newResetChallenges.isAvailable = false
		},
		removeChatMessagesFromPlayer: () => {
			const { userId } = parsedMessage as RemoveChatMessagesFromPlayerMessage
			removeMessagesFromPlayerId = userId
		},
		executeShow: () => {
			const { signal } = parsedMessage as ExecuteShow
			executeShow = signal
		},
		ping: () => {
			// do nothing
		},
		default: () => {
			console.warn(`Unhandled event ts: ${Date.now()} -> `, parsedMessage)
		},
	}

	;(eventResolvers[event] || eventResolvers['default'])()

	return {
		chatMessage,
		removeMessagesFromPlayerId,
		startCountdownShowAvailable: newStartCountdownShowAvailable,
		topThreePlayers: newTopThreePlayers,
		startGame: newStartGame,
		prepareCelebration: newPrepareCelebration,
		displayBeatGuest: newDisplayBeatGuest,
		pickWinners: newPickWinners,
		resetChallenges: newResetChallenges,
		stopShow: newStopShow,
		executeShowStatus: executeShow,
	}
}
