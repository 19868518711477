import type { ChangeEvent, FunctionComponentElement } from 'react'
import React from 'react'
import { CardBody, CardButton, CardFieldSet, CardHeader, CardHeading, CardWrapper } from '../../containers/Card'
import { TimeFrame } from '../../interfaces/TimeFrame'
import { useNextGoalsOverwriteCommand } from '../../utils/hooks/commands/useNextGoalsOverwriteCommand'
import { useNextWeeklyGoalsState } from '../../utils/hooks/dashboardInputs/useNextWeeklyGoals'
import { getOrdinal } from '../../utils/ordinal/getOrdinal'
import GoalSelect from '../GoalSelect'
import Toggle from '../Toggle'

function NextWeeklyGoals(): FunctionComponentElement<typeof CardWrapper> {
	const [nextWeeklyGoals, updateNextWeeklyGoalsState, toggleReset] = useNextWeeklyGoalsState()
	const [nextGoalsOverwriteState, overwriteNextGoalsCommand] = useNextGoalsOverwriteCommand(TimeFrame.Weekly)

	const onChangeNextWeeklyGoals = (event: ChangeEvent<HTMLSelectElement>, goalNumber: number) => {
		const {
			currentTarget: { value },
		} = event

		const newWeeklyGoals = { ...nextWeeklyGoals.value, [goalNumber]: JSON.parse(value) }

		updateNextWeeklyGoalsState({
			...newWeeklyGoals,
		})
	}

	return (
		<CardWrapper>
			<CardHeader>
				<CardHeading>Next Weekly Goals</CardHeading>
			</CardHeader>
			<CardBody>
				{Array(3)
					.fill('')
					.map((_, index) => {
						const rootKey = `nextWeeklyGoals-${index}`
						return (
							<CardFieldSet key={rootKey}>
								<label>{getOrdinal(index + 1) + ': '}</label>
								<GoalSelect
									timeFrame={TimeFrame.Weekly}
									value={nextWeeklyGoals.value}
									goalNumber={index}
									rootKey={rootKey}
									onChange={onChangeNextWeeklyGoals}
								/>
							</CardFieldSet>
						)
					})}
				<CardFieldSet>
					<Toggle label="Reset" status={nextWeeklyGoals.reset} onChange={toggleReset} />
					<CardButton type="button" disabled={nextGoalsOverwriteState.isUpdating} onClick={overwriteNextGoalsCommand}>
						Overwrite
					</CardButton>
				</CardFieldSet>
			</CardBody>
		</CardWrapper>
	)
}

export default NextWeeklyGoals
