import { Currency } from '../../../config/enums'
import type { PrizesActions } from '../interfaces/PrizesActions'
import type { PrizesState } from '../interfaces/PrizesState'
import type { PrizeState } from '../interfaces/PrizeState'
import { REDUX_DASHBOARD_INPUT_TYPES } from '../types'

const prizeInitialState: PrizeState = {
	value: 0,
	isValid: true,
}

const initialState: PrizesState = {
	currency: Currency.USD,
	tournamentChampion: { value: 100, isValid: true },
	tournamentTop10: { value: 10, isValid: true },
	beatGuestWinner: { value: 0, isValid: true },
	luckyWinner: { value: 100, isValid: true },
}

export default function prizes(state = initialState, action: PrizesActions): PrizesState {
	switch (action.type) {
		case REDUX_DASHBOARD_INPUT_TYPES.PRIZES_STATE_UPDATE: {
			const { currency, tournamentChampion, tournamentTop10, beatGuestWinner, luckyWinner } = action.props
			return {
				...initialState,
				currency,
				tournamentChampion: { ...prizeInitialState, value: tournamentChampion },
				tournamentTop10: { ...prizeInitialState, value: tournamentTop10 },
				beatGuestWinner: { ...prizeInitialState, value: beatGuestWinner },
				luckyWinner: { ...prizeInitialState, value: luckyWinner },
			}
		}
		case REDUX_DASHBOARD_INPUT_TYPES.PRIZES_STATE_VALID: {
			const { isTournamentChampionValid, isTournamentTop10Valid, isBeatGuestWinnerValid, isLuckyWinnerValid } = action.props
			return {
				...initialState,
				tournamentChampion: {
					value: state.tournamentChampion.value,
					isValid: isTournamentChampionValid,
				},
				tournamentTop10: {
					value: state.tournamentTop10.value,
					isValid: isTournamentTop10Valid,
				},
				beatGuestWinner: {
					value: state.beatGuestWinner.value,
					isValid: isBeatGuestWinnerValid,
				},
				luckyWinner: {
					value: state.luckyWinner.value,
					isValid: isLuckyWinnerValid,
				},
			}
		}
		default:
			return state
	}
}
