import type { Dispatch } from 'redux'
import { sendCommandToStudio } from '../../../services/api/serverless'
import type { DashboardInputValues } from '../../dashboardInputs/interfaces/DashboardInputValues'
import type { BaseGameSettingsProps, BlockbusterSettingsProps } from '../../dashboardInputs/interfaces/GamesSettings'
import type { StateTree } from '../../interfaces'
import type { OverwriteDefaultValuesFailureAction } from '../interfaces/actions/OverwriteDefaultValuesFailureAction'
import type { OverwriteDefaultValuesStartAction } from '../interfaces/actions/OverwriteDefaultValuesStartAction'
import type { OverwriteDefaultValuesSuccessAction } from '../interfaces/actions/OverwriteDefaultValuesSuccessAction'
import type { UpdateIndividualValue } from '../interfaces/UpdateIndividualValue'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

export const overwriteDefaultValuesStart = (): OverwriteDefaultValuesStartAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.OVERWRITE_DEFAULT_VALUES_COMMAND_START }
}

export const overwriteDefaultValuesFailure = (): OverwriteDefaultValuesFailureAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.OVERWRITE_DEFAULT_VALUES_COMMAND_FAILURE }
}

const overwriteDefaultValuesSuccess = (): OverwriteDefaultValuesSuccessAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.OVERWRITE_DEFAULT_VALUES_COMMAND_SUCCESS }
}

export default function overwriteDefaultValues() {
	return async (dispatch: Dispatch, getState: () => StateTree): Promise<void> => {
		dispatch(overwriteDefaultValuesStart())

		try {
			const {
				dashboardInputs: {
					video,
					beatGuestMessages,
					prizes,
					nextShowDateTime,
					blockbusterSettings,
					centipedeSettings,
					beatSettings,
					rallySettings,
					breakoutSettings,
					featuredGame,
					guestInfo,
					forceUpdateVersion,
					nextDailyGoals,
					nextMonthlyGoals,
					nextWeeklyGoals,
					timestampsAutomationSettings,
					analyticsCuePointsState,
				},
			} = getState()

			const {
				highQualityVideoUrl: { value: highQualityVideoUrl },
				videoUrl: { value: videoUrl },
				lowQualityVideoUrl: { value: lowQualityVideoUrl },
				guestScore: { value: guestScore },
			} = video
			const {
				yesFirst: { value: yesFirst },
				yesSecond: { value: yesSecond },
				noFirst: { value: noFirst },
				noSecond: { value: noSecond },
			} = beatGuestMessages
			const {
				currency,
				tournamentChampion: { value: tournamentChampion },
				tournamentTop10: { value: tournamentTop10 },
				beatGuestWinner: { value: beatGuestWinner },
				luckyWinner: { value: luckyWinner },
			} = prizes

			const blockbusterSettingsToSave: BlockbusterSettingsProps = {
				level: blockbusterSettings.level.value,
				stage: blockbusterSettings.stage.value,
				shots: blockbusterSettings.shots.value,
			}
			const centipedeSettingsToSave: BaseGameSettingsProps = {
				level: centipedeSettings.level.value,
				stage: centipedeSettings.stage.value,
			}
			const beatSettingsToSave: BaseGameSettingsProps = {
				level: beatSettings.level.value,
				stage: beatSettings.stage.value,
			}
			const rallySettingsToSave: BaseGameSettingsProps = {
				level: rallySettings.level.value,
				stage: rallySettings.stage.value,
			}
			const breakoutSettingsToSave: BaseGameSettingsProps = {
				level: breakoutSettings.level.value,
				stage: breakoutSettings.stage.value,
			}

			const {
				time: { value: nextShowTime },
				date: { value: nextShowDate },
			} = nextShowDateTime

			const {
				guestDetails: { value: guestDetails },
				guestDetailsPictureURL: { value: guestDetailsPictureURL },
				guestExternalURL: { value: guestExternalURL },
				guestRoomPosterPictureURL: { value: guestRoomPosterPictureURL },
				guestTitle: { value: guestTitle },
			} = guestInfo
			const {
				android: { value: android },
				iOS: { value: iOS },
			} = forceUpdateVersion
			const { value: timestampsAutomationSettingsToSave } = timestampsAutomationSettings
			const { props: analyticsCuePoints } = analyticsCuePointsState

			const defaultValues: DashboardInputValues = {
				video: { highQualityVideoUrl, videoUrl, lowQualityVideoUrl, guestScore },
				beatGuestMessages: { yesFirst, yesSecond, noFirst, noSecond },
				prizes: { currency, tournamentChampion, tournamentTop10, beatGuestWinner, luckyWinner },
				blockbusterSettings: blockbusterSettingsToSave,
				centipedeSettings: centipedeSettingsToSave,
				beatSettings: beatSettingsToSave,
				rallySettings: rallySettingsToSave,
				breakoutSettings: breakoutSettingsToSave,
				featuredGame,
				nextShowDateTime: { time: nextShowTime, date: nextShowDate },
				guestInfo: {
					guestDetails,
					guestDetailsPictureURL,
					guestExternalURL,
					guestRoomPosterPictureURL,
					guestTitle,
				},
				forceUpdateVersion: { android, iOS },
				nextDailyGoals: nextDailyGoals.value,
				nextMonthlyGoals: nextMonthlyGoals.value,
				nextWeeklyGoals: nextWeeklyGoals.value,
				timestampsAutomationSettings: timestampsAutomationSettingsToSave,
				analyticsCuePoints,
			}

			const updateDefaultValuesCommand: UpdateIndividualValue = {
				command: 'updateIndividualValue',
				key: 'defaultValues',
				value: JSON.stringify(defaultValues),
			}

			await sendCommandToStudio(updateDefaultValuesCommand)

			dispatch(overwriteDefaultValuesSuccess())
		} catch {
			dispatch(overwriteDefaultValuesFailure())
		}
	}
}
