import { useCallback } from 'react'
import type { Currency } from '../../../config/enums'
import prizesUpdate from '../../../redux/dashboardInputs/actions/prizesState'
import type { PrizeState } from '../../../redux/dashboardInputs/interfaces/PrizeState'
import type { PrizesUpdateActionProps } from '../../../redux/dashboardInputs/interfaces/PrizesUpdateActionProps'
import type { StateTree } from '../../../redux/interfaces'
import { useAppDispatch, useAppSelector } from '..'

type UsePrizesState = [Currency, PrizeState, PrizeState, PrizeState, PrizeState, (props: PrizesUpdateActionProps) => void]

export const usePrizesState = (): UsePrizesState => {
	const { currency, tournamentChampion, tournamentTop10, beatGuestWinner, luckyWinner } = useAppSelector(
		({ dashboardInputs: { prizes } }: StateTree) => prizes,
	)
	const dispatch = useAppDispatch()
	const updatePrizesState = useCallback((props: PrizesUpdateActionProps) => prizesUpdate(props)(dispatch), [dispatch])

	return [currency, tournamentChampion, tournamentTop10, beatGuestWinner, luckyWinner, updatePrizesState]
}
