import { useCallback } from 'react'
import { prizesStateValid } from '../../../redux/dashboardInputs/actions/prizesState'
import type { PrizesValidActionProps } from '../../../redux/dashboardInputs/interfaces/PrizesValidActionProps'
import type { StateTree } from '../../../redux/interfaces'
import { validatePrizes } from '../../validation/prizes'
import { useAppDispatch, useAppSelector } from '..'

export const usePickWinnersValidation = (): (() => boolean) => {
	const dispatch = useAppDispatch()
	const prizes = useAppSelector(({ dashboardInputs: { prizes } }: StateTree) => prizes)
	const statePrizesValid = useCallback(
		(props: PrizesValidActionProps) => {
			dispatch(prizesStateValid(props))
		},
		[dispatch],
	)

	return (): boolean => {
		const { isTournamentChampionValid, isTournamentTop10Valid, isBeatGuestWinnerValid, isLuckyWinnerValid } = validatePrizes(prizes)

		if (isTournamentChampionValid && isTournamentTop10Valid && isBeatGuestWinnerValid && isLuckyWinnerValid) {
			return true
		}

		statePrizesValid({ isTournamentChampionValid, isTournamentTop10Valid, isBeatGuestWinnerValid, isLuckyWinnerValid })

		return false
	}
}
