import type { ChangeEvent, FunctionComponentElement } from 'react'
import React from 'react'
import { timeFramedGoals } from '../../config/app'
import type { Goals } from '../../interfaces/Goals'
import type { TimeFrame } from '../../interfaces/TimeFrame'

interface Props {
	value?: Goals
	rootKey: string
	goalNumber: number
	timeFrame: TimeFrame
	onChange: (event: ChangeEvent<HTMLSelectElement>, goalNumber: number) => void
}

function GoalSelect({ value, rootKey, goalNumber, timeFrame, onChange }: Props): FunctionComponentElement<typeof HTMLElement> {
	return (
		<select
			name={rootKey}
			id={rootKey}
			style={{ width: '83%' }}
			value={JSON.stringify(value?.[goalNumber])}
			onChange={(event) => {
				onChange(event, goalNumber)
			}}
		>
			{timeFramedGoals[timeFrame].map((goal, index) => (
				<option key={`${rootKey}${goalNumber}-${index}`} value={JSON.stringify(goal)}>
					{goal.description}
				</option>
			))}
		</select>
	)
}

export default GoalSelect
